import React, { Component, Fragment } from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Form, withFormik } from "formik";
import { Button, Typography, Box } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import {
  FormBodyContainer,
  FormField,
  FormHeaderContainer,
  ChangeButton,
  CSZWrapper,
  ComplianceNotice
} from "../../components";
import ChildQuestionField from "./ChildQuestionField";
import ProgramOptions from "./ProgramOptions";
import YearOptions from "./YearOptions";
import { validateRequiredFiled, hasFieldError } from "../../../utils/validator";
import {
  isDataLayerLoaded,
  createGTMEvent
} from "../../../utils/googleTagManager";
import { questionActions } from "../../../state/question";
import { leadActions } from "../../../state/lead";
import { locationActions } from "../../../state/location";
import { USStates, categoryMap, aimWidgetIdList } from "../../../config";

class StepOneForm extends Component {
  state = {
    isFormComplete: false,
    isLoactionDataAdded: false,
    isCategoryDataAdded: false,
    isSelectedCategoriesDataAdded: false,
    isDefaultValuesSent: false,
    isChangeButtonClicked: false,
    isMsaDataSent: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    isDataLayerLoaded()
      .then(() => {
        createGTMEvent(
          {
            event: "Step Conversion",
            label: "Step 1",
            action: "Load"
          },
          "gtm.load"
        );
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidUpdate() {
    const {
      isLoactionDataAdded,
      isDefaultValuesSent,
      isCategoryDataAdded,
      isSelectedCategoriesDataAdded,
      isMsaDataSent
    } = this.state;
    const {
      locationData,
      leadData,
      updateLead,
      categoryId,
      schoolType,
      selectedCategoryIds,
      isFetchingMsaByZip,
      fetchMsaByZipDone,
      fetchMsaByZipFailed,
      msaData
    } = this.props;
    if (!isFetchingMsaByZip && !isMsaDataSent && fetchMsaByZipFailed) {
      const data = {
        questionName: "msaName",
        questionValue: "Not found",
        step: 1
      };
      updateLead(leadData.id, data);
      const codeData = {
        questionName: "msaCode",
        questionValue: "Not found",
        step: 1
      };
      updateLead(leadData.id, codeData);
      this.setState({ isMsaDataSent: true });
    }

    if (!isFetchingMsaByZip && !isMsaDataSent && fetchMsaByZipDone) {
      const data = {
        questionName: "msaName",
        questionValue: msaData.msaName || "Not found",
        step: 1
      };
      updateLead(leadData.id, data);
      const codeData = {
        questionName: "msaCode",
        questionValue: msaData.msaCode || "Not found",
        step: 1
      };
      updateLead(leadData.id, codeData);
      this.setState({ isMsaDataSent: true });
    }

    if (!isDefaultValuesSent && leadData.id) {
      const data = {
        questionName: "levelOfEducation",
        questionValue: "High School",
        step: 1
      };
      updateLead(leadData.id, data);
      this.setState({ isDefaultValuesSent: true });
    }

    if (locationData && leadData.id && !isLoactionDataAdded) {
      let schoolTypeData = {
        questionName: "schoolType",
        questionValue: schoolType,
        step: 1
      };
      let stateData = {
        questionName: "state",
        questionValue: locationData.state,
        step: 1
      };
      let zipData = {
        questionName: "zip",
        questionValue: locationData.zipCode,
        step: 1
      };
      let cityData = {
        questionName: "city",
        questionValue: locationData.city,
        step: 1
      };
      let msaCodeData = {
        questionName: "msaCode",
        questionValue: locationData.msa.msaCode,
        step: 1
      };
      let msaNameData = {
        questionName: "msaName",
        questionValue: locationData.msa.msaName,
        step: 1
      };
      let projectedSegmentData = {
        questionName: "projectedSegment",
        questionValue: locationData.projectedSegment,
        step: 1
      };
      updateLead(leadData.id, msaCodeData);
      updateLead(leadData.id, msaNameData);
      updateLead(leadData.id, schoolTypeData);
      updateLead(leadData.id, stateData);
      updateLead(leadData.id, zipData);
      updateLead(leadData.id, cityData);
      updateLead(leadData.id, projectedSegmentData);
      this.setState({ isLoactionDataAdded: true });
    }

    if (categoryId && leadData.id && !isCategoryDataAdded) {
      let categoryData = {
        questionName: "categoryId",
        questionValue: JSON.stringify(categoryId),
        step: 1
      };
      updateLead(leadData.id, categoryData);
      this.setState({ isCategoryDataAdded: true });
    }

    if (selectedCategoryIds && leadData.id && !isSelectedCategoriesDataAdded) {
      const selectedCategoriesData = {
        questionName: "selectedCategoryIds",
        questionValue: selectedCategoryIds,
        step: 1
      };
      updateLead(leadData.id, selectedCategoriesData);
      this.setState({ isSelectedCategoriesDataAdded: true });
    }
  }

  onNextStepButtonClicked = data => {
    const { goToNextStep, values } = this.props;

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 1 Next Button",
      action: "Click"
    });

    goToNextStep(values);
  };

  handleOnBlur = ({ target: { name } }, questionName) => {
    const {
      setFieldTouched,
      values,
      updateLead,
      leadData,
      currentStep,
      setCompletedSteps,
      errors,
      touched,
      stepCompleted,
      updateLocation,
      locationData,
      setFieldValue,
      fetchMsaCodeByZip,
      searchFeedOptions,
      widgetData
    } = this.props;

    let data = {
      questionName,
      step: currentStep,
      questionValue: values[name]
    };
    if (questionName === "militaryBranchxyz") {
      let categoryId = [values[name]];
      let mappedId = [];
      let localMappedId = [];

      for (let i = 0; i < categoryId.length; i++) {
        localMappedId = [...localMappedId, ...categoryMap[categoryId[i]]];
      }
      let localCategoryId = [...new Set(localMappedId)];
      searchFeedOptions("program", localCategoryId);
      for (let i = 0; i < categoryId.length; i++) {
        if (
          widgetData.categoryMapping &&
          widgetData.categoryMapping[categoryId[i]]
        ) {
          mappedId = [
            ...mappedId,
            ...widgetData.categoryMapping[categoryId[i]]
          ];
        } else {
          mappedId = [...mappedId, ...categoryMap[categoryId[i]]];
        }
      }
      // Move user selected category to first position
      categoryId = [...new Set(mappedId)].sort((x, y) =>
        x === +categoryId[0] ? -1 : y === +categoryId[0] ? 1 : 0
      );

      if (categoryId) {
        const updateCategory = {
          ...data,
          questionName: "categoryId",
          questionValue: JSON.stringify(categoryId)
        };
        updateLead(leadData.id, updateCategory);
      }

      data = {
        ...data,
        questionName: "selectedCategoryIds",
        questionValue: [values[name]]
      };
    }

    setFieldTouched(name, true);
    if (leadData.id && values[name] && !Object.keys(errors).includes(name)) {
      updateLead(leadData.id, data);
    }

    if (!values[name]) {
      let steps = [...stepCompleted];
      let idx = stepCompleted.findIndex(s => s === currentStep);
      if (idx > -1) {
        steps.splice(idx, 1);
      }
      setCompletedSteps(steps);
    } else {
      let steps = [...stepCompleted];
      if (!hasFieldError(errors, touched) && !steps.includes(currentStep)) {
        setCompletedSteps([...steps, currentStep]);
      }
    }

    if (
      questionName === "zip" ||
      questionName === "state" ||
      questionName === "city"
    ) {
      let updatedLocation = {
        name: questionName,
        value: values[name]
      };
      updateLocation(updatedLocation);
      if (questionName === "zip") {
        fetchMsaCodeByZip(values[name]);
        updateLocation({ name: "zipCode", value: values[name] });
        this.setState({ isMsaDataSent: false });
      }
    } else {
      if (locationData) {
        setFieldValue("zip", locationData.zipCode || locationData.zip);
        setFieldValue("city", locationData.city);
        setFieldValue("state", locationData.state);
      }
    }
  };
  onChangeButtonClicked = () => {
    createGTMEvent({
      event: "Step Conversion",
      label: "Step 1 Change Button",
      action: "Click"
    });
    this.setState({ isChangeButtonClicked: !this.state.isChangeButtonClicked });
  };

  render() {
    const {
      questions,
      allQuestions,
      locationData,
      errors,
      touched,
      values,
      currentStep,
      stepCompleted,
      categoryId,
      widgetData,
      campusCode
    } = this.props;
    const { isChangeButtonClicked } = this.state;
    let { feedOptions } = this.props;

    Object.keys(feedOptions).length > 0 &&
      Object.keys(feedOptions).forEach(
        categoryId =>
          (feedOptions[categoryId] = feedOptions[categoryId].sort((a, b) =>
            a.text > b.text ? 1 : -1
          ))
      );
    // let programs = [];
    // Object.keys(feedOptions).length &&
    //   Object.keys(feedOptions).forEach(categoryId => {
    //     if (feedOptions[categoryId].length) {
    //       feedOptions[categoryId].forEach(({ text, value }) =>
    //         programs.push({ label: text, value: value })
    //       );
    //     }
    //   });

    return (
      <Fragment>
        <FormHeaderContainer className="form-header">
          <Typography variant="h5">Education Information</Typography>
        </FormHeaderContainer>
        <FormBodyContainer className="form-body">
          <ComplianceNotice referringDomain={window.referringDomain || ""} />
          <Form>
            {questions.length &&
              questions.map((question, idx) => {
                switch (question.type) {
                  case "dropdown":
                    return !question.isDependent ? (
                      <Fragment key={idx}>
                        {!["hasRNLicense", "hasTeachingLicense"].includes(
                          question.metaKey
                        ) &&
                          !(
                            aimWidgetIdList.includes(widgetData.id) &&
                            question.metaKey === "militaryBranchxyz"
                          ) && (
                            // (question.metaKey === "programId" ? (
                            //   <Fragment key="programId">
                            //     <Typography
                            //       variant="caption"
                            //       style={{
                            //         marginTop: "20px",
                            //         fontSize: "1rem",
                            //         display: "block"
                            //       }}
                            //     >
                            //       Concentrations/Specialties
                            //     </Typography>
                            //     <Field
                            //       className="custom-select"
                            //       name={`${question.metaKey}`}
                            //       options={programs}
                            //       component={CustomSelect}
                            //       placeholder="Concentrations/Specialties"
                            //       // multiple={true}
                            //       onBlur={e => {
                            //         this.handleOnBlur(e, question.metaKey);
                            //       }}
                            //     />
                            //   </Fragment>
                            // ) : (

                            <FormField
                              name={`${question.metaKey}`}
                              label={
                                aimWidgetIdList.includes(widgetData.id) &&
                                question.metaKey === "programId"
                                  ? "Program of Interest"
                                  : question.label
                              }
                              component="select"
                              validate={validateRequiredFiled}
                              touched={touched[`${question.metaKey}`]}
                              error={errors[`${question.metaKey}`]}
                              onBlur={e => {
                                this.handleOnBlur(e, question.metaKey);
                              }}
                            >
                              <option value="">- Select -</option>
                              {question.metaKey === "programId" ? (
                                <ProgramOptions
                                  feedOptions={feedOptions}
                                  campusCode={campusCode}
                                  widgetId={widgetData.id}
                                />
                              ) : question.metaKey === "gradYear" ? (
                                <YearOptions
                                  startYear={new Date().getFullYear()}
                                ></YearOptions>
                              ) : (
                                question.options &&
                                question.options.length &&
                                question.options.map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option.text || option.name}
                                  </option>
                                ))
                              )}
                            </FormField>
                          )}

                        {question.metaKey === "hasRNLicense" &&
                          categoryId &&
                          categoryId.includes(3) && (
                            <FormField
                              name={`${question.metaKey}`}
                              label={question.label}
                              component="select"
                              validate={validateRequiredFiled}
                              touched={touched[`${question.metaKey}`]}
                              error={errors[`${question.metaKey}`]}
                              onBlur={e => {
                                this.handleOnBlur(e, question.metaKey);
                              }}
                            >
                              <option value="">- Select -</option>
                              {question.options &&
                                question.options.length &&
                                question.options.map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option.text || option.name}
                                  </option>
                                ))}
                            </FormField>
                          )}

                        {question.metaKey === "hasTeachingLicense" &&
                          categoryId &&
                          categoryId.includes(2) && (
                            <FormField
                              name={`${question.metaKey}`}
                              label={question.label}
                              component="select"
                              validate={validateRequiredFiled}
                              touched={touched[`${question.metaKey}`]}
                              error={errors[`${question.metaKey}`]}
                              onBlur={e => {
                                this.handleOnBlur(e, question.metaKey);
                              }}
                            >
                              <option value="">- Select -</option>
                              {question.options &&
                                question.options.length &&
                                question.options.map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option.text || option.name}
                                  </option>
                                ))}
                            </FormField>
                          )}

                        {question.hasDependent &&
                          question.dependents.length &&
                          question.dependents.map((dependentQuestion, idx) => {
                            let childQuestion = allQuestions.filter(
                              q =>
                                dependentQuestion.questionKey === q.metaKey &&
                                dependentQuestion.condition.includes(
                                  values[`${question.metaKey}`]
                                )
                            );
                            if (childQuestion.length) {
                              return (
                                <ChildQuestionField
                                  childQuestion={childQuestion[0]}
                                  touched={touched}
                                  errors={errors}
                                  handleOnBlur={this.handleOnBlur}
                                  key={idx}
                                />
                              );
                            }
                            return "";
                          })}
                      </Fragment>
                    ) : (
                      ""
                    );
                  default:
                    return (
                      <Fragment key={idx}>
                        <FormField
                          type="text"
                          name={`${question.metaKey}`}
                          label={question.label}
                          placeholder={question.label}
                          validate={validateRequiredFiled}
                          touched={touched[`${question.metaKey}`]}
                          error={errors[`${question.metaKey}`]}
                          onBlur={e => {
                            this.handleOnBlur(e, question.metaKey);
                          }}
                        />
                        {question.hasDependent &&
                          question.dependents.length &&
                          question.dependents.map((dependentQuestion, idx) => {
                            let childQuestion = allQuestions.filter(
                              q =>
                                dependentQuestion.questionKey === q.metaKey &&
                                dependentQuestion.condition.includes(
                                  values[`${question.metaKey}`]
                                )
                            );
                            if (childQuestion.length) {
                              return (
                                <ChildQuestionField
                                  childQuestion={childQuestion[0]}
                                  touched={touched}
                                  errors={errors}
                                  handleOnBlur={this.handleOnBlur}
                                  key={idx}
                                />
                              );
                            }
                            return "";
                          })}
                        {question.metaKey === "address" &&
                          locationData &&
                          !isChangeButtonClicked && (
                            <Box mt={2} id="city-state-zip">
                              <Typography>City, State, Zipcode</Typography>
                              <Typography color="primary">
                                {`${locationData.city}, ${
                                  locationData.state
                                }, ${
                                  locationData.zip || locationData.zipCode
                                }`}{" "}
                                <ChangeButton
                                  onClick={this.onChangeButtonClicked}
                                >
                                  Change
                                </ChangeButton>
                              </Typography>
                            </Box>
                          )}
                        {isChangeButtonClicked && (
                          <CSZWrapper>
                            <FormField
                              type="text"
                              name="city"
                              label="City"
                              placeholder="Write City"
                              validate={validateRequiredFiled}
                              touched={touched["city"]}
                              error={errors["city"]}
                              value={values.city}
                              onBlur={e => {
                                this.handleOnBlur(e, "city");
                              }}
                            />
                            <FormField
                              name="state"
                              label="State"
                              component="select"
                              validate={validateRequiredFiled}
                              touched={touched["state"]}
                              style={{ height: "36px" }}
                              error={errors["state"]}
                              onBlur={e => {
                                this.handleOnBlur(e, "state");
                              }}
                            >
                              <option value="">- Select -</option>
                              {USStates.length &&
                                USStates.map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option.text}
                                  </option>
                                ))}
                            </FormField>
                            <FormField
                              type="text"
                              name="zip"
                              label="Zip"
                              placeholder="Write zip"
                              validate={validateRequiredFiled}
                              touched={touched["zip"]}
                              error={errors["zip"]}
                              value={values.zip}
                              onBlur={e => {
                                this.handleOnBlur(e, "zip");
                              }}
                            />
                          </CSZWrapper>
                        )}
                      </Fragment>
                    );
                }
              })}

            <Box mt={2} align="right">
              <Button
                variant="contained"
                color="primary"
                onClick={this.onNextStepButtonClicked}
                disabled={
                  !stepCompleted.includes(currentStep) &&
                  hasFieldError(errors, touched)
                }
                id="step1-next-button"
              >
                Next
                <KeyboardArrowRight />
              </Button>
            </Box>
          </Form>
        </FormBodyContainer>
      </Fragment>
    );
  }
}

const StepOneQuestions = withFormik({
  mapPropsToValues: ({
    formData,
    locationData = {},
    selectedCategoryIds = []
  }) => ({
    levelOfEducation: "High School",
    ...formData,
    // programId: [],
    militaryBranchxyz:
      selectedCategoryIds !== null ? selectedCategoryIds[0] : "",
    city: locationData.city || "",
    state: locationData.state || "",
    zip: locationData.zip || locationData.zipCode || ""
  }),
  validationSchema: Yup.object().shape({
    address: Yup.string("Please enter valid address")
      .required("Please enter valid address")
      .min(4, "Please enter a valid address; must be at least 4 characters")
      .matches(
        /(?=^.{2,}$)(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z-/.\s,_]*$/,
        "accepts only letters, hyphens, commas, slashes, dots, underscores, and at least one number"
      ),
    city: Yup.string("Please enter valid city")
      .required("Please enter valid city")
      .min(2, "Please enter a valid city; must be at least 2 characters")
      .matches(/^[a-zA-Z ]*$/, "Accepts only letters, space"),
    zip: Yup.string("Please enter valid zip")
      .required("Please enter valid zip")
      .matches(/(^\d{5}$)/, "Invalid zip")
  }),
  handleSubmit(values, { props, setFieldError, setSubmitting }) {
    let data = { ...values };
    console.log(data);
  }
})(StepOneForm);

const mapStateToProps = ({ question, lead, location }) => {
  return {
    formData: question.formData,
    currentStep: question.currentStep,
    allQuestions: question.allQuestions,
    feedOptions: question.feedOptions,
    stepCompleted: question.stepCompleted,
    leadData: lead.leadData,
    locationData: location.locationData,
    msaData: location.msaData,
    fetchMsaByZipDone: location.fetchMsaByZipDone,
    fetchMsaByZipFailed: location.fetchMsaByZipFailed,
    isFetchingMsaByZip: location.isFetchingMsaByZip
  };
};

const mapActionsToProps = {
  goToNextStep: questionActions.goToNextStep,
  setCompletedSteps: questionActions.setCompletedSteps,
  updateLead: leadActions.updateLead,
  updateLocation: locationActions.updateLocation,
  fetchMsaCodeByZip: locationActions.fetchMsaCodeByZip,
  searchFeedOptions: questionActions.searchFeedOptions
};

export default connect(mapStateToProps, mapActionsToProps)(StepOneQuestions);
