import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Form, withFormik } from "formik";
import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import {
  FormBodyContainer,
  FormField,
  FormHeaderContainer,
  PreviousButton,
  Indicator,
  CustomButton,
  CCPAText
} from "../../components";
import ChildQuestionField from "./ChildQuestionField";
import {
  validateRequiredFiled,
  validatePhoneNumber,
  hasFieldError,
  hasEmptyField
} from "../../../utils/validator";
import { createGTMEvent } from "../../../utils/googleTagManager";
import {
  defaultConsentText,
  getConsentTextForMatches,
  birthAndGradYearDiff,
  maxBirthAndGradYearDiff,
  CCPAConsentText,
  assetsURL,
  incompleteWidgetId,
  pixelEventName,
  snapchatPixelEventName,
  defaultConsentTextV2,
  isTcpaV2Active
} from "../../../config";
import updateStepThreeLeadData from "../../../helpers/updateStepThreeLeadData";
import isMobileBrowser from "../../../helpers/isMobileBrowser";
import { questionActions } from "../../../state/question";
import { leadActions } from "../../../state/lead";
import { matchActions } from "../../../state/match";
import { trackActions } from "../../../state/track";
import styled from "styled-components";

const HiddenImage = styled.img`
  display: none;
`;

const uncheckedWidgetsId = [
  "50bace3d-1e98-4ddc-b888-ddc1ee3bbc2f",
  "40568c6d-a25b-4795-9fec-cb2dcb435784"
];

const schema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Letters only please")
    .min(2, "Please enter valid name")
    .required("This field is required.")
    .trim(),
  lastName: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Letters only please")
    .min(2, "Please enter valid name")
    .required("This field is required.")
    .when("firstName", {
      is: val => val && val.length > 0,
      then: Yup.string().notOneOf(
        [Yup.ref("firstName")],
        "FirstName and LastName can not be same."
      )
    })
    .trim(),
  email: Yup.string().email("Please enter a valid email address."),
  phone: Yup.string()
    .required("Please enter a valid phone number")
    .min(10, "Please enter at least 10 characters.")
    .matches(
      /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
      "Please enter valid phone number"
    )
});

class StepThreeForm extends Component {
  state = {
    bestTimeToContact: "",
    isAllQuestionValidated: false,
    isTcpaDisclosureChecked: false,
    showNotFoundMessage: false,
    isConversionEventDone: false,
    isCCPAConcentSent: false,
    isCCPAConcentChecked: true,
    leadCountEventSent: false,
    isTCPAConcentSent: false,
    isCCPACheckedByWidgetsId:
      uncheckedWidgetsId.includes(this.props.widgetData.id) &&
      this.props.locationData.state === "CA"
        ? false
        : true,
    referringDomain: "",
    isDialogOpen: false,
    isDialogBoxAlreadyOpen: false
  };

  componentDidMount() {
    const { trackPixel, leadData, trackSnapchatPixel } = this.props;
    window.scrollTo(0, 0);
    const url = new URL(window.location.href);
    const referringWebsite = url.searchParams.get("referringDomain");
    this.setState({ referringDomain: referringWebsite });
    createGTMEvent({
      event: "Step Conversion",
      label: "Step 3",
      action: "Load"
    });

    trackPixel({
      step: "3",
      eventName: pixelEventName.pageView,
      leadData
    });
    trackSnapchatPixel({
      step: "3",
      eventName: snapchatPixelEventName.pageView,
      eventTag: "Lead Conversion",
      leadData
    });
  }

  componentDidUpdate() {
    const {
      bestTimeToContact,
      isAllQuestionValidated,
      isCCPAConcentSent,
      leadCountEventSent,
      isTCPAConcentSent
    } = this.state;

    const {
      values,
      updateLead,
      completeLead,
      leadData,
      currentStep,
      questions,
      searchMatchesV2,
      locationData,
      formData,
      isSearchingMatches,
      widgetData,
      allMatches,
      searchMatchesDone,
      searchClickOutsMatches
    } = this.props;

    if (
      values.bestTimeToContact &&
      values.bestTimeToContact !== bestTimeToContact
    ) {
      this.setState({
        bestTimeToContact: values.bestTimeToContact,
        leadCountEventSent: false
      });

      let data = {
        questionName: "bestTimeToContact",
        questionValue: values.bestTimeToContact,
        step: currentStep
      };

      updateLead(leadData.id, data);

      if (locationData.state === "CA" && !isCCPAConcentSent) {
        const CCPAConcentData = {
          questionName: "hasCCPAConsent",
          questionValue: "Y",
          step: currentStep
        };
        updateLead(leadData.id, CCPAConcentData);
        this.setState({ isCCPAConcentSent: true });
      }

      if (!hasEmptyField(questions, values) && !isSearchingMatches) {
        this.setState({ isTcpaDisclosureChecked: false });

        const trustedFormField =
          document.getElementsByName("xxTrustedFormToken")[0];
        const trustedFormCertURLField = document.getElementsByName(
          "xxTrustedFormCertUrl"
        )[0];
        const trustedFormPingURLField = document.getElementsByName(
          "xxTrustedFormPingUrl"
        )[0];

        const trustedFormToken = trustedFormField ? trustedFormField.value : "";
        const trustedFormCertURL = trustedFormCertURLField
          ? trustedFormCertURLField.value
          : "";
        const trustedFormPingURL = trustedFormPingURLField
          ? trustedFormPingURLField.value
          : "";
        let searchData = {
          ...formData,
          ...values,
          trustedFormToken,
          trustedFormCertURL,
          trustedFormPingURL,
          leadId: leadData.id,
          affiliateCampaignId: widgetData.maximizerAffiliateCampaignId,
          universalLeadIdToken: leadData.universalLeadId,
          categoryId: leadData.categoryId,
          referralURL: leadData.referralURL,
          schoolType: leadData.schoolType || "Both",
          phone: values.phone.replace(/[^\d]/g, ""),
          city: locationData.city,
          state: locationData.state,
          zip: locationData.zip || locationData.zipCode,
          hasRNLicense: formData.hasRNLicense || "N",
          hasTeachingLicense: formData.hasTeachingLicense || "N",
          USMilitaryAffiliation:
            formData.USMilitaryAffiliation || "No U.S. Military Affiliation",
          widgetId: widgetData.id,
          suppressProviders: widgetData.suppressProviders || {},
          isProspexAllMatch: widgetData.isProspexAllMatch || false
        };

        if (
          Number(formData.gradYear) - Number(formData.birthYear) >
          maxBirthAndGradYearDiff
        ) {
          searchData.gradYear =
            Number(formData.birthYear) + birthAndGradYearDiff;
        }

        if (schema.isValidSync(values) && !validatePhoneNumber(values.phone)) {
          let consentData = {
            consentText: defaultConsentText,
            hasConsent: true
          };
          if (!isAllQuestionValidated) {
            updateStepThreeLeadData({
              leadData,
              updateLead,
              values,
              currentStep
            });
            completeLead(leadData.id, consentData);
            this.setState({ isAllQuestionValidated: true });
          }

          createGTMEvent({
            event: "Step Conversion",
            label: "Step 3 BestTimeToContact Dropdown",
            action: "Click"
          });
          searchMatchesV2(schema.cast({ ...searchData }));
          searchClickOutsMatches({
            siteUrl: leadData.referralURL,
            zipCode: locationData.zip || locationData.zipCode
          });
          this.setState({ showNotFoundMessage: true });
        }
      }
    }

    if (!this.state.isConversionEventDone) {
      const { isSubmittingMatches, submitMatchesDone } = this.props;

      if (!isSubmittingMatches && submitMatchesDone) {
        createGTMEvent({
          event: "Conversion"
        });
        this.setState({ isConversionEventDone: true });
      }
    }

    if (!isSearchingMatches && searchMatchesDone && !leadCountEventSent) {
      createGTMEvent({
        event: "Match Search",
        label: leadData.id,
        action: "Click",
        category: "DropDownMatchFound",
        value: allMatches.count
      });
      this.setState({ leadCountEventSent: true });
    }

    if (allMatches.count > 0 && !isTCPAConcentSent) {
      const TCPAConsentData = {
        questionName: "hasTCPAConsent",
        questionValue: "N",
        step: currentStep
      };

      const TCPAConsentText = {
        questionName: "TCPAConsentText",
        questionValue: getConsentTextForMatches(
          allMatches && allMatches.count > 0
            ? this.getSchoolNames(allMatches.matches)
            : ""
        ),
        step: currentStep
      };

      updateLead(leadData.id, TCPAConsentData);
      updateLead(leadData.id, TCPAConsentText);
      this.setState({ isTCPAConcentSent: true });
    }
  }

  onPreviousStepButtonClicked = data => {
    const { goToPreviousStep, values, errors, touched } = this.props;

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 3 Previous Button",
      action: "Click"
    });

    goToPreviousStep(values, !hasFieldError(errors, touched));
  };

  submitLeadOnNextButtonClicked = () => {
    const {
      goToNextStep,
      values,
      allMatches,
      submitMatches,
      trackPixel,
      leadData,
      trackSnapchatPixel,
      selectedSchoolOptions
    } = this.props;
    createGTMEvent({
      event: "Step Conversion",
      label: "Step 3 Next Button",
      action: "Click"
    });

    if (!isMobileBrowser()) {
      createGTMEvent({
        event: "Step Conversion",
        label: "Step 3 Next Button Desktop",
        action: "Click"
      });
    } else {
      createGTMEvent({
        event: "Step Conversion",
        label: "Step 3 Next Button Mobile",
        action: "Click"
      });
    }
    if (allMatches && allMatches.count > 0) {
      submitMatches({
        reference: allMatches.reference,
        leadId: leadData.id,
        selectedSchoolOptions: selectedSchoolOptions
      });
    }

    trackPixel({
      step: "3",
      eventName: pixelEventName.lead,
      leadData
    });
    trackPixel({
      step: "3",
      eventName: pixelEventName.placedLead,
      leadData
    });
    trackSnapchatPixel({
      step: "3",
      eventName: snapchatPixelEventName.purchase,
      eventTag: "Lead Conversion",
      leadData
    });

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 3 Lead",
      action: "Click"
    });

    goToNextStep(values);
  };

  onSearchStageTwoButtonClicked = data => {
    const {
      goToStageTwoSearch,
      resetMatchSearch,
      values,
      leadData,
      trackPixel,
      trackSnapchatPixel
    } = this.props;

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 3 View Your Matches Now Button",
      action: "Click"
    });
    trackPixel({
      step: "3",
      eventName: pixelEventName.lead,
      leadData
    });
    trackSnapchatPixel({
      step: "3",
      eventName: snapchatPixelEventName.purchase,
      eventTag: "Lead Conversion",
      leadData
    });

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 3 Lead",
      action: "Click"
    });
    resetMatchSearch();
    goToStageTwoSearch(values);
  };

  onPhoneNumberChange = ({ target: { name, value } }) => {
    if (value.length < 15) {
      const { setFieldValue } = this.props;

      let phone = value.replace(/[^\d]/g, "");

      if (phone.length === 10) {
        phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }

      setFieldValue(name, phone);
    }
  };

  handleOnBlur = ({ target: { name } }, questionName) => {
    const {
      setFieldTouched,
      values,
      updateLead,
      leadData,
      currentStep,
      setCompletedSteps,
      errors,
      touched,
      stepCompleted,
      setFieldValue
      // validateEmail
    } = this.props;

    let value = values[name];

    if (value && name === "phone") {
      value = value.replace(/[^\d]/g, "");
    }

    if (value && name === "email" && window && window.dataLayer) {
      window.dataLayer.push({ user_email: value });
    }
    let data = {
      questionName,
      questionValue: value,
      step: currentStep
    };

    setFieldTouched(name, true);
    if (leadData.id && values[name] && !Object.keys(errors).includes(name)) {
      updateLead(leadData.id, data);
    }

    if (!values[name]) {
      let steps = [...stepCompleted];
      let idx = stepCompleted.findIndex(s => s === currentStep);
      if (idx > -1) {
        steps.splice(idx, 1);
      }
      setCompletedSteps(steps);
    } else {
      let steps = [...stepCompleted];
      if (!hasFieldError(errors, touched) && !steps.includes(currentStep)) {
        setCompletedSteps([...steps, currentStep]);
      }
    }

    if (name !== "bestTimeToContact") {
      setFieldValue("bestTimeToContact", "");
      setFieldTouched("bestTimeToContact", false);
      this.setState({ bestTimeToContact: "", showNotFoundMessage: false });
    }
  };

  onTcpaDisclosureClicked = ({ target: { name, value, checked } }) => {
    const {
      // submitMatches,
      allMatches,
      leadData,
      updateLead,
      currentStep
      // trackPixel
    } = this.props;

    const { isTcpaDisclosureChecked } = this.state;

    if (checked) {
      this.props.setSelectedSchoolOptions(
        this.props.allMatches.matches.map(school => school.schoolName)
      );
    } else {
      this.props.setSelectedSchoolOptions([]);
    }
    if (checked && !isTcpaDisclosureChecked) {
      createGTMEvent({
        event: "Step Conversion",
        label: "Step 3 TCPA Checkbox",
        action: "Click"
      });

      createGTMEvent({
        event: "Match Search",
        label: leadData.id,
        action: "Click",
        category: "TCPAClickedOrganic",
        value: allMatches.count
      });

      const data = {
        questionName: "hasTCPAConsent",
        questionValue: "Y",
        step: currentStep
      };

      updateLead(leadData.id, data);
      if (isMobileBrowser()) {
        createGTMEvent({
          event: "Step Conversion",
          label: "Step 3 TCPA Checkbox Mobile",
          action: "Click"
        });
      } else {
        createGTMEvent({
          event: "Step Conversion",
          label: "Step 3 TCPA Checkbox Desktop",
          action: "Click"
        });
      }
      this.setState({ isTcpaDisclosureChecked: true });
    }

    if (!checked) {
      createGTMEvent({
        event: "Step Conversion",
        label: "Step 3 TCPA Checkbox Unchecked",
        action: "Click"
      });
    }
  };

  onCCPADisclosureClicked = () => {
    if (uncheckedWidgetsId.includes(this.props.widgetData.id)) {
      this.setState({
        isCCPACheckedByWidgetsId: !this.state.isCCPACheckedByWidgetsId
      });
    } else {
      this.setState({
        isCCPAConcentChecked: !this.state.isCCPAConcentChecked
      });
    }
  };

  getSchoolNames = matches => {
    let uniqueSchoolsSet = new Set();
    for (let i = 0; i < matches.length; i++) {
      uniqueSchoolsSet.add(matches[i].schoolName);
    }
    let uniqueSchoolsArray = Array.from(uniqueSchoolsSet);
    let schools = "";
    for (let i = 0; i < uniqueSchoolsArray.length; i++) {
      schools += uniqueSchoolsArray[i];
      if (i !== uniqueSchoolsArray.length - 1) {
        schools += "; ";
      }
    }
    return schools;
  };

  onNextStepButtonClicked = () => {
    const { allMatches, selectedSchoolOptions } = this.props;
    if (
      selectedSchoolOptions.length === allMatches.count ||
      this.state.isDialogBoxAlreadyOpen
    ) {
      this.submitLeadOnNextButtonClicked();
    } else {
      this.handleClickOpen();
    }
  };

  handleSelectAllClicked = event => {
    if (event.target.checked) {
      this.props.setSelectedSchoolOptions(
        this.props.allMatches.matches.map(school => school.schoolName)
      );
      this.setState({ isTcpaDisclosureChecked: true });
      // Need to add analytics tracking for TCPA events
    } else {
      this.props.setSelectedSchoolOptions([]);
      this.setState({ isTcpaDisclosureChecked: false });
      // Need to add analytics tracking for TCPA events
    }
  };

  handleOptionClicked = event => {
    const option = event.target.value;
    const { selectedSchoolOptions, setSelectedSchoolOptions } = this.props;
    if (selectedSchoolOptions.includes(option)) {
      setSelectedSchoolOptions(selectedSchoolOptions.filter(o => o !== option));
    } else {
      setSelectedSchoolOptions([...selectedSchoolOptions, option]);
    }

    if (selectedSchoolOptions.length > 0) {
      this.setState({ isTcpaDisclosureChecked: true });
    } else {
      this.setState({ isTcpaDisclosureChecked: false });
    }
  };

  handleClickOpen = () => {
    this.setState({ isDialogOpen: true });
    this.setState({ isDialogBoxAlreadyOpen: true });
  };

  handleClose = () => {
    this.setState({ isDialogOpen: false });
  };

  onDialogSelectAllAndSubmitButtonClicked = () => {
    this.props.setSelectedSchoolOptions(
      this.props.allMatches.matches.map(school => school.schoolName)
    );

    this.handleClose();
  };

  onDialogUserSelectedOneAndButtonClicked = () => {
    this.handleClose();
  };

  render() {
    const {
      questions,
      allQuestions,
      errors,
      touched,
      values,
      isSearchingMatches,
      searchMatchesDone,
      searchMatchesFailed,
      allMatches,
      locationData,
      selectedSchoolOptions,
      widgetData
    } = this.props;

    const {
      isAllQuestionValidated,
      isTcpaDisclosureChecked,
      showNotFoundMessage,
      isCCPAConcentChecked,
      isCCPACheckedByWidgetsId,
      referringDomain,
      isDialogOpen
    } = this.state;
    const isAllSelected = allMatches.count === selectedSchoolOptions.length;

    const parentDivStyles = {
      position: "fixed",
      bottom: "0",
      backgroundColor: "rgba(48, 48, 48, 0.65)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "16px",
      left: "0",
      right: "0",
      zIndex: "999"
    };
    const dialogAction = {
      display: "flex",
      padding: "8px",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    };

    return (
      <Fragment>
        <FormHeaderContainer className="form-header">
          <Typography variant="h5">Contact Information</Typography>
        </FormHeaderContainer>
        <FormBodyContainer className="form-body">
          <Form>
            {questions.length &&
              questions.map((question, idx) => {
                switch (question.type) {
                  case "dropdown":
                    return !question.isDependent ? (
                      <Fragment key={idx}>
                        <FormField
                          name={`${question.metaKey}`}
                          label={question.label}
                          component="select"
                          validate={validateRequiredFiled}
                          touched={touched[`${question.metaKey}`]}
                          error={errors[`${question.metaKey}`]}
                          onBlur={e => {
                            this.handleOnBlur(e, question.metaKey);
                          }}
                          disabled={allMatches.count > 0 ? true : false}
                          id={
                            question.metaKey === "bestTimeToContact"
                              ? "best-time-to-contact"
                              : ""
                          }
                        >
                          <option value="">- Select -</option>
                          {question.options &&
                            question.options.length &&
                            question.options.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.text || option.name}
                              </option>
                            ))}
                        </FormField>

                        {question.hasDependent &&
                          question.dependents.length &&
                          question.dependents.map((dependentQuestion, idx) => {
                            let childQuestion = allQuestions.filter(
                              q =>
                                dependentQuestion.questionKey === q.metaKey &&
                                dependentQuestion.condition.includes(
                                  values[`${question.metaKey}`]
                                )
                            );
                            if (childQuestion.length) {
                              return (
                                <ChildQuestionField
                                  childQuestion={childQuestion[0]}
                                  touched={touched}
                                  errors={errors}
                                  handleOnBlur={this.handleOnBlur}
                                  key={idx}
                                  disabled={allMatches.count > 0 ? true : false}
                                />
                              );
                            }

                            return "";
                          })}
                      </Fragment>
                    ) : (
                      ""
                    );
                  default:
                    return (
                      <Fragment key={idx}>
                        {question.metaKey !== "phone" && (
                          <FormField
                            type={
                              question.metaKey === "email" ? "email" : "text"
                            }
                            name={`${question.metaKey}`}
                            label={question.label}
                            placeholder={question.label}
                            validate={
                              question.metaKey === "phone"
                                ? validatePhoneNumber
                                : validateRequiredFiled
                            }
                            touched={touched[`${question.metaKey}`]}
                            error={errors[`${question.metaKey}`]}
                            onBlur={e => {
                              this.handleOnBlur(e, question.metaKey);
                            }}
                            disabled={allMatches.count > 0 ? true : false}
                          />
                        )}

                        {question.metaKey === "phone" && (
                          <FormField
                            type="text"
                            name={`${question.metaKey}`}
                            label={question.label}
                            placeholder={question.label}
                            validate={
                              question.metaKey === "phone"
                                ? validatePhoneNumber
                                : validateRequiredFiled
                            }
                            touched={touched[`${question.metaKey}`]}
                            error={errors[`${question.metaKey}`]}
                            onBlur={e => {
                              this.handleOnBlur(e, question.metaKey);
                            }}
                            onChange={this.onPhoneNumberChange}
                            disabled={allMatches.count > 0 ? true : false}
                          />
                        )}

                        {question.hasDependent &&
                          question.dependents.length &&
                          question.dependents.map((dependentQuestion, idx) => {
                            let childQuestion = allQuestions.filter(
                              q =>
                                dependentQuestion.questionKey === q.metaKey &&
                                dependentQuestion.condition.includes(
                                  values[`${question.metaKey}`]
                                )
                            );
                            if (childQuestion.length) {
                              return (
                                <ChildQuestionField
                                  childQuestion={childQuestion[0]}
                                  touched={touched}
                                  errors={errors}
                                  handleOnBlur={this.handleOnBlur}
                                  key={idx}
                                  disabled={allMatches.count > 0 ? true : false}
                                />
                              );
                            }
                            return "";
                          })}
                      </Fragment>
                    );
                }
              })}

            <Box mt={2}>
              {locationData.state === "CA" && (
                <FormField
                  id="leadid_tcpa_disclosure_CA"
                  type="checkbox"
                  name="caTcpaDisclosure"
                  label={
                    <CCPAText
                      url={
                        window.referringDomain
                          ? `https://${window.referringDomain}/privacy-policy`
                          : "#"
                      }
                    />
                  }
                  value={CCPAConsentText}
                  checked={isCCPAConcentChecked && isCCPACheckedByWidgetsId}
                  onClick={e => {
                    this.onCCPADisclosureClicked(e);
                  }}
                  disabled={values.tcpaDisclosure}
                />
              )}
              {locationData.state === "CA" && !isCCPAConcentChecked && (
                <Box mt={2} pl={3}>
                  <Typography color="error">
                    Please check CCPA concent to proceed
                  </Typography>
                </Box>
              )}

              {!isSearchingMatches && searchMatchesDone && (
                <>
                  {(!isTcpaV2Active || allMatches.count === 0) && (
                    <Indicator referringDomain={referringDomain} />
                  )}
                  {allMatches && allMatches.count === 0 && (
                    <FormField
                      id="leadid_tcpa_disclosure"
                      type="checkbox"
                      name="tcpaDisclosure"
                      label={getConsentTextForMatches("", referringDomain)}
                      value={defaultConsentText}
                      onClick={e => {
                        this.onTcpaDisclosureClicked(e);
                      }}
                      disabled={
                        !isCCPAConcentChecked || !isCCPACheckedByWidgetsId
                      }
                    />
                  )}

                  {isTcpaV2Active && allMatches && allMatches.count > 0 && (
                    <>
                      <FormField
                        type="hidden"
                        id="leadid_tcpa_disclosure"
                        name="tcpaDisclosure"
                        label={defaultConsentTextV2}
                      />
                      <Typography>
                        Select schools and submit for more information
                      </Typography>

                      <FormField
                        id="selectAll"
                        type="checkbox"
                        name="selectAll"
                        label="SELECT ALL"
                        checked={isAllSelected}
                        onClick={this.handleSelectAllClicked}
                        value="selectAll"
                        disabled={
                          !isCCPAConcentChecked || !isCCPACheckedByWidgetsId
                        }
                      />
                    </>
                  )}
                  {isTcpaV2Active &&
                    allMatches &&
                    allMatches.count > 0 &&
                    allMatches.matches.map((school, index) => (
                      <Fragment key={index}>
                        <FormField
                          id={`${school.schoolName.replace(/\s/g, "_")}_${
                            index + 1
                          }`}
                          type="checkbox"
                          name={`${school.schoolName.replace(/\s/g, "_")}_${
                            index + 1
                          }`}
                          checked={selectedSchoolOptions.includes(
                            school.schoolName
                          )}
                          onClick={this.handleOptionClicked}
                          value={school.schoolName}
                          key={school.schoolId}
                          label={school.schoolName}
                        />
                      </Fragment>
                    ))}
                  {!isTcpaV2Active && (
                    <FormField
                      id="leadid_tcpa_disclosure"
                      type="checkbox"
                      name="tcpaDisclosure"
                      label={getConsentTextForMatches(
                        allMatches && allMatches.count > 0
                          ? this.getSchoolNames(allMatches.matches)
                          : "",
                        referringDomain
                      )}
                      value={defaultConsentText}
                      onClick={e => {
                        this.onTcpaDisclosureClicked(e);
                      }}
                      disabled={
                        !isCCPAConcentChecked || !isCCPACheckedByWidgetsId
                      }
                    />
                  )}
                </>
              )}

              {!isSearchingMatches &&
              showNotFoundMessage &&
              (searchMatchesFailed ||
                (searchMatchesDone && allMatches.count < 1)) ? (
                <Box
                  mt={2}
                  align="right"
                  style={isMobileBrowser() ? parentDivStyles : {}}
                >
                  <CustomButton
                    variant="contained"
                    color="primary"
                    onClick={this.submitLeadOnNextButtonClicked}
                    id="step3-next-button"
                    fontSize="14px"
                    disabled={
                      widgetData.id === incompleteWidgetId ||
                      !isTcpaDisclosureChecked ||
                      !values.tcpaDisclosure
                    }
                  >
                    {widgetData.id === incompleteWidgetId
                      ? "No match found"
                      : "I Agree"}
                  </CustomButton>
                </Box>
              ) : (
                <>
                  {isMobileBrowser() ? (
                    <div style={parentDivStyles}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.onNextStepButtonClicked}
                        disabled={
                          (!isSearchingMatches &&
                            !searchMatchesDone &&
                            !searchMatchesFailed) ||
                          selectedSchoolOptions.length === 0
                          // !isTcpaDisclosureChecked ||
                          // !values.tcpaDisclosure
                        }
                        id="step3-next-button"
                      >
                        I Agree
                        <KeyboardArrowRight />
                      </Button>
                    </div>
                  ) : (
                    <Box mt={2} align="right">
                      <PreviousButton
                        onClick={this.onPreviousStepButtonClicked}
                        className={
                          isAllQuestionValidated && allMatches.count > 0
                            ? "disabled"
                            : ""
                        }
                        id="step3-previous-button"
                      >
                        Previous Step
                      </PreviousButton>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.onNextStepButtonClicked}
                        disabled={
                          (!isSearchingMatches &&
                            !searchMatchesDone &&
                            !searchMatchesFailed) ||
                          selectedSchoolOptions.length === 0
                          // !isTcpaDisclosureChecked ||
                          // !values.tcpaDisclosure
                        }
                        id="step3-next-button"
                      >
                        I Agree
                        <KeyboardArrowRight />
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Form>
        </FormBodyContainer>
        <HiddenImage
          src={`${assetsURL}/loader-01.gif`}
          alt="hidden loader image"
        />
        <HiddenImage
          src={`${assetsURL}/loader-02.gif`}
          alt="hidden loader image"
        />
        <Dialog
          open={isDialogOpen}
          //onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          align="center"
        >
          <Box p={4} style={{ backgroundColor: "rgb(242 242 242)" }}>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{ color: "#444444", fontFamily: "Open Sans" }}
              >
                Tip! Select more schools to make a well-informed decision.
              </DialogContentText>
            </DialogContent>
            <DialogActions style={dialogAction}>
              <Button
                onClick={this.onDialogSelectAllAndSubmitButtonClicked}
                variant="contained"
                style={{
                  backgroundColor: "#FF7833",
                  color: "#FFFFFF",
                  marginBottom: "15px",
                  justifyContent: "center",
                  fontFamily: "Roboto",
                  width: "200px"
                }}
                className="submitButton"
              >
                Select All
              </Button>
              <Button
                onClick={this.onDialogUserSelectedOneAndButtonClicked}
                variant="contained"
                style={{
                  backgroundColor: "rgb(192 218 235)",
                  color: "rgb(74 111 135)",
                  fontSize: "12px",
                  marginBottom: "15px",
                  justifyContent: "center",
                  fontFamily: "Roboto",
                  width: "200px"
                }}
                className="submitButton"
              >
                Keep my original choices
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Fragment>
    );
  }
}

const StepThreeQuestions = withFormik({
  mapPropsToValues: ({ formData }) => ({
    ...formData
  }),
  validationSchema: schema
})(StepThreeForm);

const mapStateToProps = ({
  question,
  lead,
  location,
  match,
  widget
  // validation
}) => {
  return {
    formData: question.formData,
    currentStep: question.currentStep,
    allQuestions: question.allQuestions,
    stepCompleted: question.stepCompleted,
    leadData: lead.leadData,
    isCompletingLead: lead.isCompletingLead,
    completeLeadDone: lead.completeLeadDone,
    locationData: location.locationData,
    isSearchingMatches: match.isSearchingMatches,
    searchMatchesDone: match.searchMatchesDone,
    searchMatchesFailed: match.searchMatchesFailed,
    allMatches: match.allMatches,
    widgetData: widget.widgetData,
    isSubmittingMatches: match.isSubmittingMatches,
    submitMatchesDone: match.submitMatchesDone,
    selectedSchoolOptions: match.selectedSchoolOptions
  };
};

const mapActionsToProps = {
  goToPreviousStep: questionActions.goToPreviousStep,
  goToNextStep: questionActions.goToNextStep,
  goToStageTwoSearch: questionActions.goToStageTwoSearch,
  setCompletedSteps: questionActions.setCompletedSteps,
  updateLead: leadActions.updateLead,
  completeLead: leadActions.completeLead,
  searchMatchesV2: matchActions.searchMatchesV2,
  submitMatches: matchActions.submitMatches,
  resetMatchSearch: matchActions.resetMatchSearch,
  trackPixel: trackActions.trackPixel,
  trackSnapchatPixel: trackActions.trackSnapchatPixel,
  searchClickOutsMatches: matchActions.searchClickOutsMatches,
  setSelectedSchoolOptions: matchActions.setSelectedSchoolOptions
};

export default connect(mapStateToProps, mapActionsToProps)(StepThreeQuestions);
